<template>
  <v-container grid-list-md pa-0 relative>
    <div class="title-bar-breadcrumb">
      <ul>
        <li>
          <a href="">
            <v-icon class="page-icon">home</v-icon>
            Dashboard
          </a>
        </li>
        <li>
          <a href="">
            <v-icon>keyboard_arrow_right</v-icon>
          </a>
        </li>
        <li>
          <a href="" class="active">
            <v-icon class="page-icon">layers</v-icon>
            Billing Book List
          </a>
        </li>
      </ul>
    </div>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="mb-3 elevation-1">
          <v-card class="pt-2 elevation-0"> </v-card>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            item-key="amount"
            class="elevation-0"
          >
            <template v-slot:items="props">
              <tr
                :class="'highlight'"
                @click="props.expanded = !props.expanded"
              >
                <td>{{ props.index + 1 }}</td>
                <td class="text-xs-left">
                  {{ props.item.bill_date }}
                </td>
                <td width="15%" class="text-xs-left">
                  <strong>{{ props.item.amount.numberFormat("Rs.") }}</strong>
                </td>
                <!--                                <td class="text-xs-right">-->
                <!--                                    <v-btn @click="confirmDialog=true" small icon color="primary" class="pa-0 ma-1"-->
                <!--                                           title="Sync to account">-->
                <!--                                        <v-icon color="white" small>sync_alt</v-icon>-->
                <!--                                    </v-btn>-->
                <!--                                </td>-->
              </tr>
            </template>
            <template v-slot:expand="props">
              <v-card style="width: 100%;">
                <table width="100%" class="inner-table">
                  <thead>
                    <tr>
                      <th class="text-xs-left"><strong>Fee Head</strong></th>
                      <th class="text-xs-center"><strong>Dr </strong></th>
                      <th class="text-xs-center"><strong>Cr </strong></th>
                    </tr>
                    <tr
                      align="left"
                      v-for="(x, i) in props.item.details"
                      :key="i"
                    >
                      <td>
                        {{ x.fee_head }}
                        <p v-if="x.map_ledger_head" style="font-size: 12px">
                          ({{ x.map_ledger_head.asset_ledger }})
                        </p>
                        <p v-else style="color:red;">
                          <span style="display: none">{{
                            (syncButton = true)
                          }}</span>
                          No maping found.
                        </p>
                      </td>
                      <td class="text-xs-center">{{ x.total.currency() }}</td>
                      <td></td>
                    </tr>
                    <tr
                      align="left"
                      v-for="(y, i) in props.item.details"
                      :key="form.items.data + i"
                    >
                      <td>
                        <span class="crRow"
                          >{{ y.fee_head }}
                          <p
                            class="crRow"
                            v-if="y.map_ledger_head"
                            style="font-size: 12px"
                          >
                            ({{ y.map_ledger_head.income_ledger }})
                          </p>
                          <p class="crRow" v-else style="color:red;">
                            No maping found.
                          </p>
                        </span>
                      </td>
                      <td></td>
                      <td class="text-xs-center">{{ y.total.currency() }}</td>
                    </tr>
                    <tr align="left">
                      <th align="left">Grand Total :</th>
                      <th>{{ props.item.amount.currency() }}</th>
                      <th>{{ props.item.amount.currency() }}</th>
                    </tr>
                  </thead>
                </table>
                <v-card-actions v-if="form.items.data.length">
                  <v-flex xs12 sm12 style="text-align: right">
                    <!--                                        <v-spacer/>-->
                    <textarea
                      placeholder="Narration"
                      v-model="form.narration"
                      label="Narration"
                      style="border: 1px solid #666;width: 100%;height: 80px;"
                    >
                    </textarea>
                    <v-btn
                      color="primary"
                      style="text-align: right"
                      outlined
                      small
                      @click="(confirmDialog = true), (record = props.item)"
                      >Sync to Account
                    </v-btn>
                  </v-flex>
                </v-card-actions>
              </v-card>
            </template>
          </v-data-table>
          <v-divider></v-divider>
        </v-card>
        <v-dialog v-model="confirmDialog" persistent max-width="290">
          <v-card>
            <v-card-title class="title pa-3 warning white--text">
              <v-icon class="mr-2 white--text">warning</v-icon>
              Please, wait!
            </v-card-title>
            <v-card-text class="ma-0"
              >Are you sure want to sync in Account?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                outlined
                small
                @click="confirmDialog = false"
                >No</v-btn
              >
              <v-btn color="warning" outlined small @click="save">Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import { getPropertyFromArrayObject } from "@/library/helpers";

export default {
  data: () => ({
    confirmDialog: false,
    form: new Form(
      {
        billingIds: "",
        narration: "",
        incomeHeads: [],
        assetsHeads: [],
      },
      "/api/invoice/bill-book"
    ),
    record: [],
    search: null,
    rowsPerPageItems: [5, 10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 10,
    },

    headers: [
      { text: "#", align: "left", value: "id", sortable: false },
      { text: "Date", align: "left", value: "date", sortable: false },
      { text: "Amount", align: "left", value: "amount", sortable: false },
      // {text: 'Action', align: 'right', value: 'action', sortable: false},
    ],
  }),
  computed: {
    ...mapState(["batch"]),
  },

  mounted() {
    this.get();
  },

  watch: {
    batch: function(value) {
      this.get();
    },

    pagination: function() {
      this.get();
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&")
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {});
    },

    save() {
      this.form.billingIds = this.record.ids;
      this.form.assetsHeads = this.record.details.map((res) => {
        return {
          assets_ledger_head_id: res.map_ledger_head.asset_ledger_head_id,
          amount: res.total,
        };
      });
      this.form.incomeHeads = this.record.details.map((res) => {
        return {
          income_ledger_head_id: res.map_ledger_head.income_ledger_head_id,
          amount: res.total,
        };
      });
      this.$rest
        .post("api/journal-entry/bill-book", this.form)
        .then((data) => {
          this.confirmDialog = false;
          this.$events.fire("notification", {
            message: data.message,
            status: "success",
          });
        })
        .catch((e) => {
          this.confirmDialog = false;
          this.$events.fire("notification", {
            message: e.message,
            status: "error",
          });
        });
      // this.form.store()
    },
  },
};
</script>
<style lang="scss">
.highlight {
  td {
    font-weight: bold !important;
    cursor: pointer;
  }
}
</style>
